import { createContext, useContext, useEffect, useState } from 'react';
import { useChannel } from '../Channel/ChannelProvider';
import { Measure } from '@formify/projects-state-manager';

interface MeasureUnitProviderContextValues {
    availableMeasureUnit: ('MM' | 'DIN')[];
    currentMeasureUnit: 'MM' | 'DIN';
    setMeasureUnit: (measureUnitCode: 'MM' | 'DIN') => void;
    format: (value: Measure) => [string, string];
}

const measureUnitContext = createContext<MeasureUnitProviderContextValues>({
    availableMeasureUnit: [],
    currentMeasureUnit: 'MM',
    setMeasureUnit: () => {},
    format: () => ['0', 'MM'],
});

export const useMeasureUnit = () => useContext(measureUnitContext);

interface MeasureUnitProviderProps {
    children: React.ReactNode;
}

export const MeasureUnitProvider = ({ children }: MeasureUnitProviderProps) => {
    const { defaultUnit, handledUnits } = useChannel();
    const [currentMeasureUnit, setMeasureUnit] = useState<'MM' | 'DIN'>(defaultUnit);

    useEffect(() => {
        setMeasureUnit((currentUnit) => {
            if (handledUnits.includes(currentUnit)) {
                return currentUnit;
            }

            return handledUnits[0] ?? currentUnit;
        });
    }, [handledUnits, defaultUnit]);

    return (
        <measureUnitContext.Provider
            value={{
                availableMeasureUnit: handledUnits,
                currentMeasureUnit,
                setMeasureUnit,
                format: (value: Measure) => {
                    if (currentMeasureUnit === 'MM') {
                        return [value.getCentimeters().toString(), 'cm'];
                    }

                    return [value.getInches().toFixed(1), 'in'];
                },
            }}
        >
            {children}
        </measureUnitContext.Provider>
    );
};
