import { ChannelService } from '../channel/channel';
import { FetchService } from '../fetch/fetch';
import { isObjectWithKey } from '../validateData';

export class TranslationService {
    private fetch: FetchService;
    private channel: ChannelService;
    private responseCache: { [lang: string]: Promise<unknown> | undefined } = {};
    private normalizeLabels: { [lang: string]: { [key: string]: string } } = {};
    private promiseNormalizeLabels: { [lang: string]: Promise<{ [key: string]: string }> } = {};

    constructor(fetch: FetchService, channel: ChannelService) {
        this.fetch = fetch;
        this.channel = channel;
    }

    async getAvailableLanguages(): Promise<
        {
            code: string;
            name: string;
        }[]
    > {
        return this.channel.getAvailableLanguages();
    }

    async getLabel(language: string, key: string, defaultValue: string): Promise<string> {
        const labels = await this.getNormalizeLabels(language);
        const label = labels[key];

        this.createLabelIfNotExist(key, defaultValue);

        return label ?? defaultValue;
    }

    async getNormalizeLabels(language: string): Promise<{ [key: string]: string }> {
        const result =
            this.promiseNormalizeLabels[language] ??
            this.getLabels(language).then((translations) => {
                const isDebug = localStorage.getItem('debug') === '1';
                const normalizeLabels: { [key: string]: string } = {};

                for (const key in translations) {
                    const value = translations?.[key]?.[language] ?? '';

                    if (isDebug) {
                        normalizeLabels[key] = `Key: ${key}`;
                    } else {
                        normalizeLabels[key] = value;
                    }
                }
                this.normalizeLabels[language] = normalizeLabels;

                return normalizeLabels;
            });

        this.promiseNormalizeLabels[language] = result;

        return result;
    }

    async getLabels(language: string): Promise<TranslationResponseRaw> {
        const request =
            this.responseCache[language] ??
            Promise.race([
                // TODO: after fix cache issue in the backend, use this line instead of the next one
                // this.fetch.get(`/api/v2/shop/translations?locale=${language}`),
                this.fetch.get('/api/v2/shop/translations'),
                new Promise((reject) => {
                    setTimeout(
                        () => {
                            reject(new Error('Timeout'));
                        },
                        5000,
                        null,
                    );
                }),
            ]);

        this.responseCache[language] = request;
        try {
            const values = await request;

            if (isTranslationResponseRaw(values)) {
                return values;
            }
        } catch (error) {
            this.responseCache[language] = undefined;

            return {}; // null
        }

        return {};
    }

    async createLabelIfNotExist(key: string, value: string) {
        if (this.normalizeLabels === null) {
            return false;
        }
        if (localStorage.getItem('debug') === '1') {
            return false;
        }
        const languages = await this.getAvailableLanguages();
        const isExist = languages.some(({ code }) => this.normalizeLabels[code]?.[key] !== undefined);

        if (isExist) {
            return false;
        }

        languages.forEach(({ code }) => {
            const lang = this.normalizeLabels[code] || {};

            this.normalizeLabels[code] = lang;
            lang[key] = value;
        });

        try {
            await this.fetch.post('/api/v2/shop/translations', {
                key,
                translations: [
                    {
                        locale: 'en_US',
                        value,
                    },
                ],
            });

            return true;
            // eslint-disable-next-line no-empty
        } catch (error) {}

        return false;
    }

    applyVariables(label: string, variables: { [key: string]: string | number }): string {
        return label.replace(/\${\s*(\w+)\s*}/gi, (match, key) => `${variables[key] || match}`);
    }
}

type TranslationResponseRaw = { [key: string]: { [lang: string]: string } };

function isTranslationResponseRaw(value: any): value is TranslationResponseRaw {
    return isObjectWithKey(value) && Object.values(value).every(isObjectWithKey);
}
