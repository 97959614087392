import React, { FC } from 'react';
import { Container } from './CookiesConsent.styled';
import { TextS, TextXS } from '@formify/frontend-components';
import { setCookie } from 'cookies-next';
import { Button } from '@formify/frontend-components';
import { useDispatch, useSelector } from 'react-redux';
import { getIsCookieVisible } from '../../store/layout/selectors';
import { setCookieVisibility } from '../../store/layout/actions';
import Link from 'next/link';
import { useEnv } from '../../hooks/useEnv';
import { Tr } from '../Translations/Tr';

export const CookiesConsent: FC = () => {
    const isVisible = useSelector(getIsCookieVisible);
    const dispatch = useDispatch();
    const cookiePolicyRoute = useEnv('COOKIE_POLICY_URL') ?? '';

    if (!isVisible) {
        return null;
    }

    return (
        <Container>
            <TextS fontWeight={400}>
                <Tr labelKey="cookiesConsent.privacy" defaultValue="COOKIES AND PRIVACY" />
            </TextS>
            <TextXS className="mt-10 mb-28 text-center">
                <Tr
                    labelKey="cookiesConsent.info"
                    defaultValue="Cookies improve your experience by browsing our site you agree to our"
                />
                <Link
                    href={cookiePolicyRoute}
                    target={cookiePolicyRoute.startsWith('http') ? '_blank' : undefined}
                    prefetch={false}
                    passHref
                    className="underline font-normal ml-4"
                >
                    <Tr labelKey="cookiesConsent.policy" defaultValue="cookies policy." />
                </Link>
            </TextXS>
            <Button
                onClick={() => {
                    setCookie('cookies_consent', true, {
                        maxAge: 356 * 24 * 60 * 60,
                        path: '/',
                    });
                    dispatch(setCookieVisibility(false));
                }}
            >
                <Tr labelKey="cookiesConsent.accept" defaultValue="I AGREE" />
            </Button>
        </Container>
    );
};
