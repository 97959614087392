import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouterParams } from '../../hooks/useRouterParams';
import { RootState } from '../../store';
import { fetchOrderData } from '../../store/project/actions';

interface CurrencyProviderContextValues {
    availableCurrency: string[];
    currentSymbol: string;
    currentCurrency: string;
    setCurrency: (currencyCode: string) => void;
    formatPrice: (price: number | string, overwriteCurrency?: string) => string;
}

const currencyContext = createContext<CurrencyProviderContextValues>({
    availableCurrency: [],
    currentSymbol: 'EUR',
    currentCurrency: 'EUR',
    setCurrency: () => {},
    formatPrice: (price: number | string, overwriteCurrency?: string) => `${price} ${overwriteCurrency}`,
});

export const useCurrency = () => useContext(currencyContext);

interface CurrencyProviderProps {
    children: React.ReactNode;
}

const getCurrencySymbol = (currencyCode: string) => {
    const options: Intl.NumberFormatOptions = { style: 'currency', currency: currencyCode };
    const numberFormat = new Intl.NumberFormat('en-US', options);
    const parts = numberFormat.formatToParts(1);
    const symbol = parts.find((p) => p.type === 'currency')?.value || currencyCode;

    return symbol;
};

export const CurrencyProvider = ({ children }: CurrencyProviderProps) => {
    const [currentCurrency, setCurrentCurrency] = useState<string>('EUR');
    const { params } = useRouterParams<{ currency: string }>();
    const [availableCurrency, setAvailableCurrency] = useState<string[]>([]);
    const currencyService = useSelector((state: RootState) => state.dependency.services?.currency);
    const cartService = useSelector((state: RootState) => state.dependency.services?.cart);
    const dispatch = useDispatch<any>();

    const setCurrency = useCallback(
        (currencyCode: string) => {
            setCurrentCurrency((prev) => {
                if (currencyCode === prev) {
                    return prev;
                }
                currencyService && currencyService.setCurrency(currencyCode);
                cartService &&
                    cartService.changeCurrencyCode(currencyCode).then(() => {
                        dispatch(fetchOrderData());
                    });

                return currencyCode;
            });
        },
        [currencyService, cartService, dispatch],
    );

    useEffect(() => {
        if (!currencyService) {
            return;
        }
        currencyService.getCurrencySettings().then(({ currencies, defaultCurrency }) => {
            const saved = currencyService.getCurrentCurrency();

            setCurrentCurrency(saved || defaultCurrency);
            cartService?.changeCurrencyCode(saved || defaultCurrency);
            setAvailableCurrency(currencies);
        });

        if (params.currency && availableCurrency.includes(params.currency)) {
            setCurrency(params.currency);
        }
    }, [availableCurrency, cartService, currencyService, params.currency, setCurrency]);

    const currentSymbol = useMemo(() => getCurrencySymbol(currentCurrency), [currentCurrency]);

    const formatPrice = useCallback(
        (price: number | string, overwriteCurrency?: string) =>
            `${typeof price === 'number' ? Math.floor(price / 100) : '-'}\xA0${
                overwriteCurrency ? getCurrencySymbol(overwriteCurrency) : currentSymbol
            }`,
        [currentSymbol],
    );

    return (
        <currencyContext.Provider
            value={{
                availableCurrency,
                currentSymbol,
                currentCurrency: currentCurrency,
                formatPrice,
                setCurrency,
            }}
        >
            {children}
        </currencyContext.Provider>
    );
};
