import { PUBLIC_ENV_CODES, PublicEnv } from './domain';
import { isGenericArray, isObjectWithKey } from '../validateData';
import { fetchUnAuthorizationApi } from '../fetch/fetch';
import { API_HOST_CONFIGS } from '../../scripts/apiHostConfigs';

let cacheEnvResponseData: Promise<PublicEnv> | null = null;

export async function getPublicEnv(keyName: keyof PublicEnv): Promise<string> {
    // FOR NODE JS - BUILD PROCESS

    if (typeof window === 'undefined') {
        const env = process.env[keyName];

        if (env === undefined) {
            throw new Error(`Environment variable ${keyName} is not defined`);
        }

        return env;
    }
    // FOR BROWSER
    cacheEnvResponseData =
        cacheEnvResponseData ||
        fetchEnvs().catch((err) => {
            cacheEnvResponseData = null;

            throw err;
        });

    return cacheEnvResponseData.then((env: PublicEnv) => (env[keyName] || '').toString());
}

const fetchEnvs = async (): Promise<PublicEnv> => {
    const api = getApiByCurrentHost();
    const response = await fetchUnAuthorizationApi(`${api.host}/api/v2/shop/attributes`, {});

    if (!isGenericArray(response, isAttributeRaw)) {
        throw new Error('Invalid response');
    }

    return convertAttributesResponseToEnvObject(response);
};

const convertAttributesResponseToEnvObject = (response: AttributeRaw[]): PublicEnv => {
    const api = getApiByCurrentHost();

    const result: { [key: string]: string } = {
        NEXT_PUBLIC_API_URL: `${api.host}/api/v2`,
        NEXT_PUBLIC_API_DOMAIN: api.host,
    };

    PUBLIC_ENV_CODES.forEach((key) => {
        if (result[key] === undefined) {
            result[key] = '';
        }
    });

    response.forEach((item) => {
        result[item.code] = item.value.value;
    });

    if (!isPublicEnv(result)) {
        throw new Error('Invalid response');
    }

    return result;
};

const isPublicEnv = (env: any): env is PublicEnv => {
    if (!isObjectWithKey(env)) {
        return false;
    }

    return PUBLIC_ENV_CODES.every((key) => env[key] !== undefined);
};

interface AttributeRaw {
    code: string;
    value: {
        value: string;
    };
}

const isAttributeRaw = (attribute: any): attribute is AttributeRaw =>
    isObjectWithKey(attribute) &&
    typeof attribute.code === 'string' &&
    isObjectWithKey(attribute.value) &&
    typeof attribute.value.value === 'string';

type Api = { channel: string; host: string };

export const getApiByCurrentHost = (): Api => {
    if (typeof window === 'undefined') {
        throw new Error('getApiByCurrentHost function should be used only in browser');
    }
    const currentUrl = window.location.host;
    const api = API_HOST_CONFIGS[currentUrl];

    if (!api) {
        throw new Error(`Api not found for host: ${currentUrl}`);
    }

    return api;
};

export const getChannelCode = (): string => {
    const api = getApiByCurrentHost();

    return api.channel;
};
