import React, { FC, useEffect } from 'react';
import { ImageProps } from 'next/image';
import Image from 'next/image';

const shimmerPath = '/images/shimmer.svg';
const brokenPath = '/images/broken.svg';

const HIDE_IMG_ALT_STYLE = {
    color: 'transparent',
};

export const ImageWithShimmerEffect: FC<
    ImageProps & {
        strictWidth?: number;
    }
> = ({ strictWidth, ...props }) => {
    const [src, setSrc] = React.useState(props.src);
    const [numberOfTryDownloadAgain, setNumberOfTryDownloadAgain] = React.useState(0);

    useEffect(() => {
        if (numberOfTryDownloadAgain > 5) {
            setSrc(brokenPath);
        } else if (shimmerPath === src) {
            const timeId = setTimeout(() => {
                setSrc(props.src);
                setNumberOfTryDownloadAgain((numberOfTryDownloadAgain) => numberOfTryDownloadAgain + 1);
            }, 2000);

            return () => clearTimeout(timeId);
        }
    }, [numberOfTryDownloadAgain, props.src, src]);

    useEffect(() => {
        setSrc(props.src);
        setNumberOfTryDownloadAgain(0);
    }, [props.src]);

    if (strictWidth) {
        let finalSrc = src;

        if (process.env.NEXT_PUBLIC_ENVIRONMENT_NAME === 'local') {
            finalSrc = `https://www.formifydev.com/${src}`;
        } else {
            finalSrc = `/${src}`;
        }
        const pixelFactors = window.devicePixelRatio === 1 ? [1] : [1, window.devicePixelRatio];

        return (
            // eslint-disable-next-line @next/next/no-img-element
            <img
                alt={props.alt}
                width={props.width}
                height={props.height}
                decoding="async"
                srcSet={pixelFactors.map((factor) => `${finalSrc}?width=${strictWidth * factor} ${factor}x`).join(', ')}
                src={`${finalSrc}?width=${strictWidth}`}
                style={{
                    ...HIDE_IMG_ALT_STYLE,
                    ...props.style,
                }}
            />
        );
    }

    return (
        <Image
            {...props}
            style={{
                ...HIDE_IMG_ALT_STYLE,
                ...props.style,
            }}
            src={src}
            placeholder="blur"
            blurDataURL={shimmerPath}
            alt={props.alt}
            onError={(e) => {
                e.currentTarget.src = shimmerPath;
                e.currentTarget.srcset = '';
                setSrc(shimmerPath);
            }}
        />
    );
};
