import type { FirebaseApp } from 'firebase/app';
import { Auth, NextOrObserver, User, sendPasswordResetEmail } from 'firebase/auth';
import JWT from 'jwt-decode';
import { pushEvent } from '../analytics/analytics';
import { IAuth, authServiceFactory } from './authServiceFactory';
import { getDefaultFirebaseApp } from '../firebase/firebaseApp';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { getRootServices } from '..';
import { getChannelCode, getPublicEnv } from '../env/env';

const MINIMUM_RESERVE_SECONDS_TO_EXPIRE = 5;

export class FirebaseAuthService implements IAuth {
    private app: FirebaseApp | null = null;
    private auth: Promise<Auth> | null = null;
    private syliusTokenFetch: Promise<any> = Promise.resolve(null);

    async getApp(): Promise<FirebaseApp> {
        if (this.app) {
            return this.app;
        }

        const app = await getDefaultFirebaseApp();

        this.app = app;

        return app;
    }

    async getAuth() {
        if (this.auth) {
            return this.auth;
        }

        this.auth = new Promise<Auth>((resolve) => {
            import('firebase/auth').then(async ({ getAuth }) => {
                const auth = getAuth(await this.getApp());

                auth.onIdTokenChanged(() => {
                    this.syliusTokenFetch = this.getNewToken();
                });

                resolve(auth);
            });
        });

        return this.auth;
    }

    async waitForNewToken() {
        await this.syliusTokenFetch;
    }

    private async revokeToken() {
        const apiToken = getCookie('apiToken');

        if (typeof apiToken === 'string') {
            deleteCookie('apiToken');
        }
    }

    async resetPasswordByEmail(email: string) {
        const auth = await this.getAuth();

        await sendPasswordResetEmail(auth, email);
    }

    private async getNewToken(): Promise<string | null> {
        const auth = await this.getAuth();
        const user = auth.currentUser;

        if (user === null) {
            return null;
        }
        try {
            const token = await user.getIdToken();
            const data = await apiTokenHandler(token);

            if (typeof data?.token === 'string') {
                return data.token;
            }
        } catch (error) {
            return null;
        }

        return null;
    }

    async getSyliusToken(): Promise<string | null> {
        return new Promise<string | null>((resolve, reject) => {
            this.syliusTokenFetch
                .then((syliusToken) => {
                    if (syliusToken && this.checkValidAccessToken(syliusToken)) {
                        return syliusToken;
                    } else {
                        this.syliusTokenFetch = this.getNewToken();

                        return this.syliusTokenFetch;
                    }
                })
                .then(resolve)
                .catch(reject);
        });
    }

    private checkValidAccessToken = (accessToken: string): boolean => {
        let payload;

        try {
            payload = JWT<{
                exp: number;
            }>(accessToken);
        } catch (error) {
            return false;
        }

        return payload.exp - MINIMUM_RESERVE_SECONDS_TO_EXPIRE >= this.getCurrentUnixTimestamp();
    };

    private getCurrentUnixTimestamp = (): number => (new Date().getTime() / 1000) | 0;

    async signInWithIOS() {
        const { signInWithPopup, OAuthProvider } = await import('firebase/auth');
        const auth = await this.getAuth();
        const provider = new OAuthProvider('apple.com');

        provider.addScope('email');
        provider.addScope('name');

        await signInWithPopup(auth, provider);

        await this.waitForNewToken();

        pushEvent('login', {
            method: 'google',
        });
    }

    async signInWithGoogle() {
        const { GoogleAuthProvider, signInWithPopup } = await import('firebase/auth');
        const auth = await this.getAuth();
        const provider = new GoogleAuthProvider();

        await signInWithPopup(auth, provider);

        await this.waitForNewToken();

        pushEvent('login', {
            method: 'google',
        });
    }

    async signInWithEmail(email: string, password: string) {
        const { signInWithEmailAndPassword } = await import('firebase/auth');
        const auth = await this.getAuth();

        await signInWithEmailAndPassword(auth, email, password);

        await this.waitForNewToken();

        pushEvent('login', {
            method: 'email',
        });
    }

    async createUserWithEmailAndPassword(email: string, password: string) {
        const { createUserWithEmailAndPassword } = await import('firebase/auth');
        const auth = await this.getAuth();

        await createUserWithEmailAndPassword(auth, email, password);

        await this.waitForNewToken();

        pushEvent('login', {
            method: 'email',
        });
    }

    async signOut() {
        const auth = await this.getAuth();

        await this.revokeToken();

        await auth.signOut();
    }

    async onAuthStateChanged(nextOrObserver: NextOrObserver<User>) {
        const auth = await this.getAuth();
        const { onAuthStateChanged } = await import('firebase/auth');

        onAuthStateChanged(auth, nextOrObserver);
    }

    async confirmPasswordReset(actionCode: string, newPassword: string) {
        const auth = await this.getAuth();
        const { verifyPasswordResetCode, confirmPasswordReset } = await import('firebase/auth');
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const email = await verifyPasswordResetCode(auth, actionCode);

        await confirmPasswordReset(auth, actionCode, newPassword);

        // await this.signInWithEmail(email, newPassword);
    }

    async verifyEmailAddress(code: string) {
        const auth = await this.getAuth();
        const { applyActionCode } = await import('firebase/auth');

        await applyActionCode(auth, code);
    }
}

async function apiTokenHandler(token: string) {
    const apiUrl = await getPublicEnv('NEXT_PUBLIC_API_URL');

    const response = await fetch(`${apiUrl}/shop/authentication-firebase`, {
        method: 'POST',
        headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
            'x-channel': getChannelCode(),
        },
        body: JSON.stringify({
            token,
        }),
    });

    if (response.status === 403) {
        throw new Error('User is not enabled');
    }

    const data: {
        token: string;
    } = await response.json();

    const payload = JWT<{
        exp: number;
    }>(data.token);

    setCookie('apiToken', data.token, {
        path: '/',
        expires: new Date(payload.exp * 1000),
    });

    const auth = authServiceFactory(data.token);
    const { cart } = getRootServices(auth);

    await cart.getCurrentCartToken();

    return data;
}
