import { createContext, useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouterParams } from '../../hooks/useRouterParams';
import { RootState } from '../../store';
import { setLocale } from 'yup';
import { VALIDATION_EMAIL_ERROR } from '../ContextMenu/components/SaveProject/validators/saveProjectFormValidator';

export interface LanguageContextValues {
    currentLanguage: string;
    labels: { [key: string]: string };
    setLanguage: (language: string) => void;
    pluralRules: Intl.PluralRules['select'];
    t: (key: string, defaultValue?: string, variables?: { [key: string]: string | number }) => string;
}

const defaultPluralRules = () => 'other' as Intl.LDMLPluralRule;

const languageContext = createContext<LanguageContextValues>({
    currentLanguage: '',
    labels: {},
    setLanguage: () => {},
    pluralRules: defaultPluralRules,
    t: (key: string, defaultValue?: string) => defaultValue || '',
});

export const useLanguage = () => useContext(languageContext);

interface LanguageProviderProps {
    children: React.ReactNode;
}

export const LanguageProvider = ({ children }: LanguageProviderProps) => {
    const { params } = useRouterParams<{ locale: string }>();
    const [currentLanguage, setCurrentLanguage] = useState<string>('en_US');
    const [pluralRules, setPluralRules] = useState<Intl.PluralRules['select'] | null>(null);
    const [labels, setLabels] = useState<{ [key: string]: string }>({});

    const setLanguage = useCallback((language: string) => {
        localStorage.setItem('language', language);
        setCurrentLanguage(language);
    }, []);

    useLayoutEffect(() => {
        if (typeof document !== 'undefined') {
            const lang = localStorage.getItem('language');

            if (lang) {
                setCurrentLanguage(lang);
                const rules = new Intl.PluralRules(lang.replace('_', '-'));

                setPluralRules(() => rules.select.bind(rules));
            }
        }
    }, []);

    const translationService = useSelector((state: RootState) => state.dependency.services?.translation);

    useEffect(() => {
        if (!translationService) {
            return;
        }
        translationService.getNormalizeLabels(currentLanguage).then((newLabels) => {
            setLabels(newLabels);
        });
        translationService.getAvailableLanguages().then((languages) => {
            if (params.locale && languages.find((lang) => lang.code === params.locale)) {
                setLanguage(params.locale);
            }
        });
    }, [currentLanguage, params.locale, setLanguage, translationService]);

    const cartService = useSelector((state: RootState) => state.dependency.services?.cart);

    useEffect(() => {
        if (!cartService) {
            return;
        }
        cartService.changeLocaleCode(currentLanguage);
    }, [currentLanguage, cartService]);

    const t = (key: string, defaultValue?: string, variables?: { [key: string]: string | number }): string => {
        const defaultValueOrKey = defaultValue ?? key;

        if (translationService) {
            translationService.createLabelIfNotExist(key, defaultValueOrKey).then((labelAdded) => {
                labelAdded &&
                    translationService.getNormalizeLabels(currentLanguage).then((newLabels) => {
                        setLabels(newLabels);
                    });
            });
        }
        const value = labels[key] ?? defaultValueOrKey;

        if (translationService && variables) {
            return translationService.applyVariables(value, variables);
        }

        return value;
    };

    useEffect(() => {
        setLocale({
            mixed: {
                default: t('validation.error.invalidField', 'Invalid field'),
                required: t('validation.error.requiredField', 'This field is required'),
                oneOf: t('validation.error.oneOf', 'Mandatory field'),
            },
            string: {
                email: t('validation.error.email', VALIDATION_EMAIL_ERROR),
            },
            number: {
                integer: t('validation.error.integer', 'Please enter a integer number'),
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLanguage]);

    return (
        <languageContext.Provider
            value={{
                labels,
                pluralRules: pluralRules ?? defaultPluralRules,
                currentLanguage: currentLanguage,
                setLanguage,
                t,
            }}
        >
            {children}
        </languageContext.Provider>
    );
};
